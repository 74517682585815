<template>
  <div>
    <div id="property-table">
      <v-card-title class="pt-1 pb-3">
        <v-text-field
          dense
          v-model="searchTextFilter"
          append-icon="mdi-magnify"
          label="Buscar..."
          single-line
          hide-details
        ></v-text-field>
        <v-btn class="btn btn-info float-right">Nueva propiedad</v-btn>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="properties"
        :items-per-page="25"
        class="elevation-1 mx-2"
        :loading="loading"
        loading-text="Cargando Asesores inmobiliarios..."
        :sort-by="['created_at']"
        :sort-desc="[true]"
      >
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import moment from "moment";
moment.locale("es");

export default {
  components: {
    // PropertySelectContractStatus: () =>
    // import("@/components/property/PropertySelectContractStatus.vue")
  },
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          value: "name"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Rol de usuario",
          value: "role"
        },
        {
          text: "Foto de perfil",
          value: ""
        }
      ],
      search: "",
      snack: false,
      snackColor: "",
      snackText: ""
    };
  },
  computed: {
    ...mapState({
      loading: state => state.properties.loading,
      property: state => state.properties.actualItem
    }),
    ...mapGetters("properties", {
      properties: "filteredItems"
    }),
    searchTextFilter: {
      get() {
        return this.$store.state.properties.filterText;
      },
      set(newValue) {
        this.$store.commit(
          "properties/setFilterText",
          newValue.trim().toLowerCase()
        );
      }
    }
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD-MMM-YYYY h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    }
  },
  created() {
    // this.consultProperties();
  },
  methods: {
    ...mapActions({
      fetchProperties: "properties/fetchProperties",
      fetchUpdatelProperty: "properties/fetchUpdatelProperty"
    }),
    ...mapMutations({
      setLoading: "properties/setLoading",
      updateActualProperty: "properties/updateActualProperty"
    }),
    // priceFormat(price, currency, status) {
    //   if (parseFloat(price) <= 0 || isNaN(price) || status === "vendido") {
    //     return "-";
    //   }
    //   return (
    //     "$" +
    //     parseFloat(price)
    //       // .toFixed(2)
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //       .concat(" " + currency)
    //   );
    // },
    // getSquareMeterPrice(price, currency, totalBuilding) {
    //   if (!price || !currency || !totalBuilding) return "";
    //   const smp = parseInt(price / totalBuilding)
    //     .toString()
    //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //     .concat(" " + currency);
    //   return smp;
    // },
    // consultProperties() {
    //   let self = this;
    //   self.setLoading(true);
    //   setTimeout(() => {
    //     this.fetchProperties()
    //       .then()
    //       .finally(() => {
    //         setTimeout(() => {
    //           self.setLoading(false);
    //         }, 1000);
    //       });
    //   }, 1500);
    // },
    // updatePropertyList() {
    //   this.consultProperties();
    // },
    // updateProperty(propertyId, data) {
    //   this.handleUpdateProperty(propertyId, data);
    //   this.snack = true;
    //   this.snackColor = "success";
    //   this.snackText = "Data saved";
    // },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    }
    // handleUpdateProperty(propertyId, propertyData) {
    //   // una vez cambiado la propiedad actual, actualizar el valor en Vuex
    //   let data = {
    //     propertyId,
    //     property: propertyData,
    //   };

    //   let self = this;
    //   this.$snotify.async(
    //     "Actualizando propiedad",
    //     "Actualizando ...",
    //     () =>
    //       new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //           this.fetchUpdatelProperty(data)
    //             .then(() => {
    //               resolve({
    //                 title: "Propiedad editada",
    //                 body: "La propiedad se ha editado correctamente.",
    //                 config: {
    //                   closeOnClick: true,
    //                   titleMaxLength: 30,
    //                   timeout: 4000,
    //                 },
    //               });
    //             })
    //             .catch((err) => {
    //               reject({
    //                 title: "Error!!!",
    //                 body: `Ocurrio un problema actualizar la propiedad. ${err}`,
    //                 config: {
    //                   closeOnClick: true,
    //                   timeout: 4000,
    //                 },
    //               });
    //             })
    //             .finally(() => {
    //               setTimeout(() => {
    //                 self.setLoading(false);
    //               }, 1000);
    //             });
    //         }, 500);
    //       })
    //   );
    // },
    // getContractStatusOption(option, propertyId) {
    //   if (option) this.contract_status = option;
    //   this.updateProperty(propertyId, { contract_status: option });
    // },
    // // end listeners
    // // pasar a una librería
    // getSafeNestedField(fn, defaultVal) {
    //   try {
    //     return fn();
    //   } catch (e) {
    //     return defaultVal;
    //   }
    // },
  }
};
</script>
<style>
.property-contratct-status-tag:hover {
  cursor: pointer;
}
.property-contratct-status-tag.disponible {
  text-transform: uppercase;
  background-color: green !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.disponible:hover {
  text-transform: uppercase;
  background-color: green !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.reservado {
  text-transform: uppercase;
  background-color: orange !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.reservado:hover {
  text-transform: uppercase;
  background-color: orange !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.vendido {
  text-transform: uppercase;
  background-color: #f84b57 !important;
  color: white !important;
  font-weight: bold;
}
.property-contratct-status-tag.vendido:hover {
  text-transform: uppercase;
  background-color: #f84b57 !important;
  color: white !important;
  font-weight: bold;
}
</style>
